<template>
    <div class="zainCashBox">
        <div class="totalBox">
          <div>{{ $t('zainTotalPay') }}</div>
          <div>{{ $t('zainIqd') }} {{ money }}</div>
        </div>
        <div class="tableBox">
          <!-- <p>{{ $t('zainName') }} </p> -->
          <!-- <div>
            <input type="text" v-model="payInfo.name" :placeholder="$t('zainNameTips')">
            <div v-if="lraqNameTips" class="errorTips"> {{ $t('zainNameNotNull') }}</div>
          </div> -->
          <p>{{ $t('zainPhoneNumber') }}</p>
          <div>
            <input type="text" v-model="payInfo.phoneNumber" :placeholder="$t('zainPhoneNumberTips') ">
            <div v-if="lraqPhoneTips" class="errorTips"> {{ $t('zainNumberErrorTips')}}</div>
          </div>
        </div>
        <div class="submitBtn" @click="createOrder">{{ $t('zainSubmit') }}</div>
        <div class="loadingBox" v-if="loadingShow">
          <div>
            <span></span>
          </div>
        </div>
    </div>
   
  </template>
  
  <script>
  import { Toast } from "vant";
  export default {
    name: "raveParty",
    data() {
        return{
          payInfo:{
            channel:'Zaincash',
            name:'user_name',
            phoneNumber:'',
            // channelConfigId:417,
            // clOutsideId:371,
            // mallId: 148,
            // orderType:'2',
            // payType: 50001,
            // sid:0,
            // sourceId:'1',
          },
          lraqNameTips: false,
          lraqPhoneTips: false,
          userInfo:{},
          money:0,
          loadingShow: false
        }
    },
    created() {
      this.payInfo = {...this.payInfo, ...this.$route.query}
      this.money = this.$route.query.money || 0;
      //调用原生方法
      this.$completes.complete("getUserInfo");
      //原生调用h5方法
      window.getUserInfo = this.getUserInfo;
      window["getUserInfo"] = (data) => {
        this.getUserInfo(data);
      };
      document.title = this.$t('zainCashTitle')
      document.body.style.backgroundColor = '#F5F6F8';
      // 判断平台
      this.judgeClient()
  
      // this.getUserInfo() 
  
      
  
    },
    methods: { 
      createOrder(){
        // if(this.payInfo.name.trim().length <= 0 || /[\u4e00-\u9fa5]/.test(this.payInfo.name) ){
        //     this.lraqNameTips = true;
        //     return
        // }
        // this.lraqNameTips = false;
        var regex = /^[\d+-\/]+$/;
        if(!regex.test(this.payInfo.phoneNumber)){
            this.lraqPhoneTips = true;
            return
        }
        this.lraqPhoneTips = false;
        let headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang,
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
        // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt4T1RndyIsInh5eiI6Ik5ESXdNelk0UVVJdFJVVTVRUzAwTmtSRkxVRkdPREV0TlRRMk1qSkJPRVU0TlRFMyIsImV4cCI6MTk4MjgyODQ2NX0.96eNpfFrvCpYCu7NHiSoiPdmvOVc7juJ8VcSz8w3XeY';
        // this.uid = 791980;
        // let headers = {
        //   fid: 2001,
        //   os: 1,
        //   lang: 'zh-CN',
        //   version: '1.24.0',
        //   deviceid: '420368AB-EE9A-46DE-AF81-54622A8E8517',
        // }
        this.loadingShow = true;
      this.$axios.api(`${this.$serviceIpJava}api/trade/order/create?uid=${this.uid}&token=${this.token}`,this.payInfo,'POST',headers).then((data) => {
          if (data.code == 200) {
            window.location.replace(data.data.url)
            this.loadingShow = false;
          }else {
            Toast(data.message || this.$t('failed'))
            this.loadingShow = false;
          }
        })
      },
      getUserInfo(data) {
        this.userInfo = JSON.parse(data);
        // this.headers = [
        //   'Content-Type:' + 'application/json; charset=utf-8',
        //   'deviceId:' + JSON.parse(data).deviceId,
        //   'lang:' + JSON.parse(data).lang,
        //   'version:' + JSON.parse(data).version,
        //   'os:' + JSON.parse(data).os,
        //   'model:' + JSON.parse(data).model,
        //   'fid:' + JSON.parse(data).fid,
        //   'uid:' + JSON.parse(data).userId
        // ]
        this.token = JSON.parse(data).token
        this.uid = JSON.parse(data).userId
        let langs = this.userInfo.lang.substring(0,2)
        let lang = this.userInfo.lang
        if (langs == "zh") {
            if (lang == "zh_TW" || lang == "zh_HK") {
            this.$i18n.locale = "tc";
            } else {
            this.$i18n.locale = "zh";
            }
        } else if (langs == "en") {
            this.$i18n.locale = "en";
        } else if (langs == "ar") {
            this.$i18n.locale = "ar";
        }else if (langs == "tr") {
            this.$i18n.locale = "tr";
        } else if (langs == "es") {
            this.$i18n.locale = "es";
        } else if (langs == "pt") {
            this.$i18n.locale = "pt";
        } else {
            this.$i18n.locale = "en";
        }
        document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    },
  };
  </script>
  
  <style scoped>
  @import './index.css'
  </style>
  